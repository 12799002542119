<!-- src/components/Header.vue -->
<script setup>
import { RouterLink } from 'vue-router';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useMatchMedia } from '../stores/matchMedia';
import { onClickOutside } from '@vueuse/core';

// 상태 및 참조 정의
const mobileSize = useMatchMedia('(max-width: 1023px)');
const isShow2023Menu = ref(false);
const isShow2024Menu = ref(false);
const header = ref(null);
const mobileMenu = ref(null);
const headerHeight = ref(60);

// 이벤트 핸들러 정의
const handleResize = () => {
  headerHeight.value = header.value?.getBoundingClientRect().height || 60;
};

const handleClick2023 = () => {
  isShow2023Menu.value = true;
  isShow2024Menu.value = false;
};

const handleClick2024 = () => {
  isShow2024Menu.value = true;
  isShow2023Menu.value = false;
};

const handleToggle = () => {
  isShow2023Menu.value = !isShow2023Menu.value;
};

const handleMouseOver2023 = () => {
  isShow2023Menu.value = true;
  isShow2024Menu.value = false;
};

const handleMouseLeave2023 = () => {
  isShow2023Menu.value = false;
};

const handleMouseOver2024 = () => {
  isShow2024Menu.value = true;
  isShow2023Menu.value = false;
};

const handleMouseLeave2024 = () => {
  isShow2024Menu.value = false;
};

// 마운트 및 언마운트 시 이벤트 리스너 설정
onMounted(() => {
  window.addEventListener('resize', handleResize);
  handleResize(); // 초기 헤더 높이 설정
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

// 외부 클릭 시 메뉴 닫기
onClickOutside(mobileMenu, () => {
  isShow2023Menu.value = false;
  isShow2024Menu.value = false;
});
</script>

<template>
  <div class="header" ref="header">
    <RouterLink to="/" class="logo-container">
      <img alt="logo" class="logo" src="@/assets/logo_gnb.png" />
    </RouterLink>

    <nav v-if="!mobileSize">
      <RouterLink to="/intro" class="menu-button">피어업 소개</RouterLink>
      <div
        class="menu-button"
        @touchstart="handleClick2023"
        @mouseover="handleMouseOver2023"
        @mouseleave="handleMouseLeave2023"
      >
        2023
        <transition name="fade">
          <div v-if="isShow2023Menu" class="menu">
            <RouterLink to="/2023/participants" class="menu-item">참여자</RouterLink>
            <RouterLink to="/2023/build-up-1" class="menu-item">빌드업-1</RouterLink>
            <RouterLink to="/2023/build-up-2" class="menu-item">빌드업-2</RouterLink>
            <RouterLink to="/2023/ping-pong-chat" class="menu-item">핑퐁챗</RouterLink>
            <RouterLink to="/2023/hovering-flight" class="menu-item">정지비행</RouterLink>
            <RouterLink to="/2023/exhibition" class="menu-item">교류전시주간</RouterLink>
          </div>
        </transition>
      </div>
      <div
        class="menu-button"
        @touchstart="handleClick2024"
        @mouseover="handleMouseOver2024"
        @mouseleave="handleMouseLeave2024"
      >
        2024
        <transition name="fade">
          <div v-if="isShow2024Menu" class="menu">
            <RouterLink to="/2024/participants" class="menu-item">참여자</RouterLink>
            <!-- <RouterLink to="/2024/build-up-1" class="menu-item">빌드업-1</RouterLink>
            <RouterLink to="/2024/build-up-2" class="menu-item">빌드업-2</RouterLink>
            <RouterLink to="/2024/ping-pong-chat" class="menu-item">핑퐁챗</RouterLink>
            <RouterLink to="/2024/hovering-flight" class="menu-item">정지비행</RouterLink>
            <RouterLink to="/2024/exhibition" class="menu-item">교류전시주간</RouterLink> -->
          </div>
        </transition>
      </div>
    </nav>
    
    <nav v-else>
      <div class="menu-button" @click="handleToggle" ref="mobileMenu">
        <span class="material-symbols-outlined" v-if="!isShow2023Menu && !isShow2024Menu">menu</span>
        <span class="material-symbols-outlined" v-if="isShow2023Menu || isShow2024Menu">close</span>
        <transition name="fade">
          <div v-if="isShow2023Menu || isShow2024Menu" class="menu">
            <!-- 2023년도 -->
            <RouterLink to="/intro" class="menu-item first">피어업 소개</RouterLink>
            <RouterLink to="/2023/participants" class="menu-item first">2023</RouterLink>
            <RouterLink to="/2023/participants" class="menu-item second">참여자</RouterLink>
            <RouterLink to="/2023/build-up-1" class="menu-item second">빌드업-1</RouterLink>
            <RouterLink to="/2023/build-up-2" class="menu-item second">빌드업-2</RouterLink>
            <RouterLink to="/2023/ping-pong-chat" class="menu-item second">핑퐁챗</RouterLink>
            <RouterLink to="/2023/hovering-flight" class="menu-item second">정지비행</RouterLink>
            <RouterLink to="/2023/exhibition" class="menu-item second">교류전시주간</RouterLink>
            <!-- 2024년도 -->
            <RouterLink to="/2024" class="menu-item first">2024</RouterLink>
            <RouterLink to="/2024/participants" class="menu-item first">참여자</RouterLink>
            <!-- <RouterLink to="/2024/build-up-1" class="menu-item second">빌드업-1</RouterLink>
            <RouterLink to="/2024/build-up-2" class="menu-item second">빌드업-2</RouterLink>
            <RouterLink to="/2024/ping-pong-chat" class="menu-item second">핑퐁챗</RouterLink>
            <RouterLink to="/2024/hovering-flight" class="menu-item second">정지비행</RouterLink>
            <RouterLink to="/2024/exhibition" class="menu-item second">교류전시주간</RouterLink> -->
          </div>
        </transition>
      </div>
    </nav>
  </div>
</template>


<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
