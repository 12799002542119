// src/router/routes.js

import HomeView from '../views/HomeView.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import('../views/IntroView.vue'),
    meta: { title: 'PEER-UP!' }
  },
  {
    path: '/2023/participants',
    name: 'participants',
    component: () => import('../views/2023/ParticipantsView.vue')
  },
  {
    path: '/2023/build-up-1',
    name: 'build-up-1',
    component: () => import('../views/2023/BuildUp1View.vue')
  },
  {
    path: '/2023/build-up-2',
    name: 'build-up-2',
    component: () => import('../views/2023/BuildUp2View.vue')
  },
  {
    path: '/2023/ping-pong-chat',
    name: 'ping-pong-chat',
    component: () => import('../views/2023/PingPongChatView.vue')
  },
  {
    path: '/2023/hovering-flight',
    name: 'hovering-flight',
    component: () => import('../views/2023/HoveringFlightView.vue')
  },
  {
    path: '/2023/exhibition',
    name: 'exhibition',
    component: () => import('../views/2023/ExhibitionView.vue')
  },
  {
    path: '/2023/exhibition/1',
    name: 'exhibition-1',
    component: () => import('../views/2023/Exhibition1View.vue')
  },
  {
    path: '/2023/exhibition/2',
    name: 'exhibition-2',
    component: () => import('../views/2023/Exhibition2View.vue')
  },
  {
    path: '/2023/exhibition/3',
    name: 'exhibition-3',
    component: () => import('../views/2023/Exhibition3View.vue')
  },

  //2024년도 업데이트 
  {
    path: '/2024',
    name: '2024',
    component: () => import('../views/2024/2024View.vue')
  },
  {
    path: '/2024/participants',
    name: '2024 participants',
    component: () => import('../views/2024/ParticipantsView.vue')
  }



];
